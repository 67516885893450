<template>
  <div>
    <b-card>
      <brand-filter :get-filtered-data="getReport" />
    </b-card>
    <loading v-if="loadingReport" />
    <template v-else>
      <total-card />
      <b-card
        v-for="report in reportData"
        :key="report.id"
        :title="report.brand"
      >
        <div class="mb-2">
          <statistics
            :statistics-data="report.statistics"
          />
        </div>
        <stock-table
          :models-data="report.models"
          :totals-data="report.totals"
        />
      </b-card>
    </template>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import BrandFilter from '@/views/Reports/Sales/Stocks/BrandFilter.vue'
import Statistics from '@/views/Reports/Sales/Stocks/Statistics.vue'
import StockTable from '@/views/Reports/Sales/Stocks/Table.vue'
import Loading from '@/views/Reports/Sales/Stocks/LoadingReport.vue'
import TotalCard from '@/views/Reports/Sales/Stocks/TotalCard.vue'

export default {
  name: 'Stocks',
  components: {
    BrandFilter,
    Statistics,
    StockTable,
    Loading,
    TotalCard,
    BCard,
  },
  computed: {
    reportData() {
      return this.$store.getters['salesStocksReport/getReport']
    },
    filterData() {
      return this.$store.getters['salesStocksReport/getFilterData']
    },
    loadingReport() {
      return this.$store.getters['salesStocksReport/getLoading']
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      this.$store.dispatch('salesStocksReport/report', this.filterData)
    },
  },
}
</script>
