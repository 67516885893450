<template>
  <b-row>
    <b-col>
      <v-select
        v-model="filterData.id_com_brand"
        :options="brands"
        label="title"
        :reduce="brand => brand.id"
        placeholder="Marka"
      />
    </b-col>
    <b-col cols="auto">
      <b-button
        variant="primary"
        @click="getFilteredData"
      >
        <FeatherIcon icon="FilterIcon" />
        Filtrele
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'BrandFilter',
  components: {
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  props: {
    getFilteredData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    filterData() {
      return this.$store.getters['salesStocksReport/getFilterData']
    },
  },
  created() {
    this.getBrands()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
  },
}
</script>
