<template>
  <b-table-simple
    class="m-0 p-0"
    hover
    caption-top
    responsive
    small
  >
    <b-thead>
      <b-tr class="bg-gradient-primary">
        <b-th
          class="font-small-1 text-nowrap align-middle bg-transparent"
        >
          ŞASE
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          ARAÇ CİNSİ
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          RENK
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          GÜN SAYISI
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          DURUM
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          BAĞLANTI
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          FATURA TARİHİ
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          VADE TARİHİ
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          VALÖR
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent text-right">
          ALIM TUTARI
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <template v-for="(detail,index) in detailData">
        <b-tr
          :key="index"
          :class="'bg-gradient-' + detail.rowClass"
        >
          <b-td class="font-small-1 text-light">
            {{ detail.chasis }}
          </b-td>
          <b-td class="font-small-1 text-light">
            {{ detail.text }}
          </b-td>
          <b-td class="font-small-1 text-light">
            {{ detail.color }}
          </b-td>
          <b-td class="font-small-1 text-light">
            {{ detail.stock_days }}
          </b-td>
          <b-td class="font-small-1 text-light">
            {{ detail.status_text }}
          </b-td>
          <b-td class="font-small-1 text-light">
            {{ detail.connected_text }}
          </b-td>
          <b-td class="font-small-1 text-light">
            {{ moment(detail.invoice_date).format('DD.MM.YYYY') }}
          </b-td>
          <b-td class="font-small-1 text-light">
            {{ moment(detail.maturity).format('DD.MM.YYYY') }}
          </b-td>
          <b-td class="font-small-1 text-light">
            {{ detail.value_date }}
          </b-td>
          <b-td class="text-right font-small-1 text-light">
            {{ detail.purchase | toCurrency }} ₺
          </b-td>
        </b-tr>
      </template>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BTbody, BTd, BTh, BThead, BTr,
} from 'bootstrap-vue'

export default {
  name: 'DetailTable',
  components: {
    BTableSimple, BTbody, BTd, BTh, BThead, BTr,
  },
  props: {
    detailData: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
