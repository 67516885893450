<template>
  <b-row>
    <b-col>
      <item-statistics
        color="light-primary"
        :value="statisticsData.total"
        title="Stok"
        icon="InfoIcon"
      />
    </b-col>
    <b-col>
      <item-statistics
        color="light-warning"
        :value="statisticsData.connected"
        title="Bağlantı"
        icon="LinkIcon"
      />
    </b-col>
    <b-col>
      <item-statistics
        color="light-success"
        :value="statisticsData.days_0_30"
        title="0-30 Gün"
        icon="CheckCircleIcon"
      />
    </b-col>
    <b-col>
      <item-statistics
        color="light-warning"
        :value="statisticsData.days_31_60"
        title="31-60 Gün"
        icon="InfoIcon"
      />
    </b-col>
    <b-col>
      <item-statistics
        color="light-danger"
        :value="statisticsData.days_61"
        title="61+ Gün"
        icon="XCircleIcon"
      />
    </b-col>
    <b-col>
      <item-statistics
        color="light-info"
        :value="statisticsData.value_date"
        title="Valör"
        icon="BarChartIcon"
      />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import ItemStatistics from '@/views/Reports/Sales/Stocks/ItemStatistics.vue'

export default {
  name: 'Statistics',
  components: {
    BRow,
    BCol,
    ItemStatistics,
  },
  props: {
    statisticsData: {
      type: Object,
      required: true,
    },
  },
}
</script>
