<template>
  <b-card title="Genel Toplam">
    <b-row>
      <b-col>
        <item-statistics
          color="light-primary"
          :value="grandTotal.total"
          title="Stok"
          icon="InfoIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          color="light-warning"
          :value="grandTotal.connected"
          title="Bağlantı"
          icon="LinkIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          color="light-success"
          :value="grandTotal.days_0_30"
          title="0-30 Gün"
          icon="CheckCircleIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          color="light-warning"
          :value="grandTotal.days_31_60"
          title="31-60 Gün"
          icon="InfoIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          color="light-danger"
          :value="grandTotal.days_61"
          title="61+ Gün"
          icon="XCircleIcon"
        />
      </b-col>
      <b-col>
        <item-statistics
          color="light-info"
          :value="grandTotal.value_date"
          title="Valör"
          icon="BarChartIcon"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import ItemStatistics from '@/views/Reports/Sales/Stocks/ItemStatistics.vue'

export default {
  name: 'TotalCard',
  components: {
    ItemStatistics,
    BCard,
    BRow,
    BCol,
  },
  computed: {
    grandTotal() {
      return this.$store.getters['salesStocksReport/getGrandTotal']
    },
  },
}
</script>
